.user-view-table {
  table-layout: fixed;

  td {
    padding-right: 0;
    padding-left: 0;
    border: 0;
  }

  td:first-child {
    width: 9rem;
  }

  td:not(:first-child) {
    min-width: 12rem;
  }
}

.user-edit-fileinput {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.user-edit-multiselect ~ .select2-container {
  width: 100% !important;
}
