.react-select__indicator.custom-toggle {
  width: 36px;
  padding: 8px;
  border: 0;
  background: none !important;
  &.btn:disabled {
    color: #babbbc !important;
    opacity: 1 !important;
  }
  &:hover {
    cursor: default;
  }
  &::after {
    display: none;
  }
}

.custom-dropdown-menu {
  border-bottom: solid 1px #ecedee;
  font-size: 10px;
}

.custom-input {
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  flex: 1;
  outline: 0;
  border-radius: 4px;
}

.clear-icon {
  display: flex;
  height: 100%;
  padding: 13px;
  width: 36px;
  cursor: pointer;
}
